import React from "react";
import { Content } from "patterns/Content";
import { ContentBlock } from "patterns/ContentBlock";
import { MastheadNewsletter } from "patterns/MastheadNewsletter";
import { Shell } from "patterns/Shell";

import "../../scss/main.scss";

function NewsletterIssue4({
    location: {
        pathname,
    },
}) {
    return (
        <Shell
            pageDescription="Website progress, leads for Jamstack devs and more"
            pageSocialImage=""
            pageTitle="Issue #4"
            pathname={pathname}
        >
            <MastheadNewsletter
                authorImage={{
                    alt: "",
                    dimensions: {
                        height: 500,
                        width: 500,
                    },
                    url: "https://images.prismic.io/robsimpson/e65250e1-4617-44f6-b54a-a5034f29dda7_avatar.png?auto=compress,format"
                }}
                authorName="Rob Simpson"
                title="Issue #4"
            />
            <div className="container-large">
                <Content
                    content={[
                        {spans: [], text: "Yes, it’s that time again, the end of another month. Like many, it marks a year since I started working from home.", type: "paragraph"},
                        {spans: [], text: "Looking back, if it weren’t for lockdown I don’t think I’d be freelancing right now – it made that leap far less intimidating because fewer variables would be changing from being employed to self-employed because my day-to-day life would be very much the same.", type: "paragraph"},
                        {spans: [], text: "Whilst I think we can all agree this past year has sucked, I’m glad something positive came from it and hope it has for you too.", type: "paragraph"},
                    ]}
                />
                <ContentBlock
                    title="🗓 Looking back at March"
                    content={[
                        {spans: [], text: "👷 Work", type: "heading4"},
                        {spans: [], text: "March has been a bit of a strange month work-wise. I had a key project booked in but this kept getting pushed back, so the first half of March I’ve mostly been doing work for the agency I used to work for prior to freelancing.", type: "paragraph"},
                        {spans: [], text: "Towards the end of the month, I finally kicked off the project I’ve had booked in since December. My hunch is, this project is going to go over budget slightly, but it’s going to be great once it’s finished and I’m learning some new things so I’m happy to take a little bit of a hit.", type: "paragraph"},
                        {spans: [], text: "Other than that, I also helped another agency split out one of their client’s sites into 2 areas “personal” and “business”. It required a bit of thought, but I managed to reconfigure the Prismic pages ever so slightly so that Gatsby builds the page under its respective area.", type: "paragraph"},
                        {spans: [], text: "📬 Inquiries", type: "heading4"},
                        {spans: [], text: "In March I received quite a lot of new leads... but I lost out on all of them because everyone is looking for a developer who can start ASAP – it’s unfortunate to have to turn away so many potentially good projects.", type: "paragraph"},
                        {spans: [], text: "💻 Website progress", type: "heading4"},
                        {spans: [], text: "One of my goals for March was to start building my new website – 15 components to be exact. Well, I smashed that, I built way more than I set out to.", type: "paragraph"},
                        {spans: [], text: "I spent a few hours a day for a week and I’ve built around 80% of the components that I need for my new website and I’m really happy with how it’s looking so far.", type: "paragraph"},
                        {spans: [{
                            start: 86,
                            end: 95,
                            type: "hyperlink",
                            data: {
                                link_type: "Web",
                                url: "https://robsimpsonstorybook.netlify.app/",
                            },
                        }], text: "If you’re interested in seeing what it looks like, make sure to check out my websites Storybook.", type: "paragraph"},
                        {spans: [], text: "📸 Instagram...", type: "heading4"},
                        {spans: [], text: "In last months newsletter I said I was going to make a push to grow my Instagram account... well I gave up on that.", type: "paragraph"},
                        {spans: [], text: "Why? It was a combination of reasons, but creating new content was taking up quite a lot of my downtime – which I really needed to spend on more important tasks like building my website.", type: "paragraph"},
                        {spans: [], text: "I also want to get my website live so I can start publishing content on a platform that I own (my website) where I can write more in-depth articles around the stuff I want to share on Instagram.", type: "paragraph"},
                        {spans: [], text: "💌 Buttondown", type: "heading4"},
                        {spans: [{
                            start: 87,
                            end: 97,
                            type: "hyperlink",
                            data: {
                                link_type: "Web",
                                url: "https://buttondown.email/",
                            },
                        }], text: "If you recall in last months newsletter I was considering moving my newsletter over to Buttondown. Well, I’ve actually had a play around with it now and I’ll be making the move once my website is done.", type: "paragraph"},
                        {spans: [], text: "I had a few issues whilst playing around with it, but I messaged Justin (the creator of Buttondown) and he was really helpful. He even gave me access to a hidden feature in the admin area so I can create a template for my posts.", type: "paragraph"},
                        {spans: [], text: "Once it’s all setup I think it’ll be much easier to push out new content.", type: "paragraph"},
                    ]}
                />
                <ContentBlock
                    title="🥅 March goals"
                    checkList={[
                        {complete: false, text: "📸 Share 27 Instagram posts"},
                        {complete: true, text: "🏗 Build 15 components for my new website"},
                        {complete: false, text: "🌟 Land a decent sized project for April"},
                    ]}
                    contentSize="large"
                />
                <ContentBlock
                    title="🗓 Looking ahead to April"
                    content={[
                        {spans: [], text: "I set myself a goal to land a decent-sized project for April, but with the push backs on the project I’m working on now and new business from old clients I didn’t need to land a big project.", type: "paragraph"},
                        {spans: [], text: "So April will mostly be finishing off a Gatsby, Prismic project which should go live before the end of the month (assuming content entry doesn’t take forever).", type: "paragraph"},
                        {spans: [], text: "Outside of that, I have some new features to add to 2 Gatsby, Prismic projects I completed for clients last year and some design work for an accessibility project so that will keep me ticking over until the end of the month.", type: "paragraph"},
                    ]}
                />
                <ContentBlock
                    title="🥅 April goals"
                    checkList={[
                        {complete: false, text: "🚀 Launch my new business website"},
                        {complete: false, text: "🚀 Launch my new personal website"},
                        {complete: false, text: "🌟 Land a decent sized project for May"},
                    ]}
                    contentSize="large"
                />
                <ContentBlock
                    title="💡 Freelance tip of the month"
                    quote="If you want consistent leads, make sure that people can find you. Think like your potential client and where they might be trying to find you from – focus your efforts on that (website, SEO, social network(s) etc.)"
                />
                <ContentBlock
                    title="💰 Earnings for the month"
                    image={{
                        alt: '',
                        dimensions: {
                            height: 867,
                            width: 1396,
                        },
                        url: 'https://images.prismic.io/robsimpson/edae9ea9-f0a0-40cf-b694-c453b77e5aea_mar-2021-income.png?auto=compress,format'
                    }}
                    content={[
                        {spans: [], text: "Income: £8.8k", type: "paragraph"},
                    ]}
                    contentSize="large"
                />
                <ContentBlock
                    title="📦 Product of the month"
                    image={{
                        alt: '',
                        dimensions: {
                            height: 867,
                            width: 1396,
                        },
                        url: 'https://images.prismic.io/robsimpson/cc4fc25c-7e4f-4850-a63c-9661d0913aff_phox-walter-filter.png?auto=compress,format'
                    }}
                    content={[
                        {spans: [], text: "Phox eco friendly water filter", type: "heading4"},
                        {spans: [], text: "I got a coffee machine this month and I needed a way to filter my water so it doesn’t ruin the coffee machine. As you probably guessed I needed something that was eco friendly and wouldn’t result in a surplus of single-use plastic and so I ended up going with the Phox water filter which has a refillable filter.", type: "paragraph"},
                    ]}
                    buttonText="View product"
                    buttonUrl={{
                        link_type: "Web",
                        url: "https://www.phoxwater.com/products/phox-v2-water-filter",
                    }}
                />
                <ContentBlock
                    title="📙 Book of the month"
                    image={{
                        alt: '',
                        dimensions: {
                            height: 867,
                            width: 1396,
                        },
                        url: 'https://images.prismic.io/robsimpson/761f5187-e56b-4347-bec9-fc13ee50d736_book-atomic-habits.png?auto=compress,format'
                    }}
                    content={[
                        {spans: [], text: "Atomic Habits by James Clear", type: "heading4"},
                        {spans: [], text: "This is a book that’s been on my read list for a while now and it keeps popping up wherever I go so I decided to finally take the plunge and order it. It’s a really interesting read when it comes to building habits and how you can best achieve them. Unknowingly I already follow some of the suggestions in the book.", type: "paragraph"},
                    ]}
                    buttonText="View book"
                    buttonUrl={{
                        link_type: "Web",
                        url: "https://www.amazon.co.uk/Atomic-Habits-Proven-Build-Break/dp/1847941834",
                    }}
                />
            </div>
        </Shell>
    );
}

export default NewsletterIssue4;
